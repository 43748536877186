import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { down } from '@thomaseckhardt/styled-breakpoints'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article'
import JumpNav from '../components2/JumpNav'

const Main = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1fr;

  > * {
    grid-column: 1 / span 16;
    min-height: 70vh;
  }

  > *:nth-child(2) {
    grid-row: 1;
    min-height: 0;
  }
`

const MainContent = styled.article`
  grid-column: 1 / span 16;
  min-height: 70vh;
`

const JumpNavContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1 / span 3;
  z-index: 1;

  ${down('laptop')} {
    display: none;
  }
`

const StyledJumpNav = styled(JumpNav)`
  position: sticky;
  top: 0;
  padding-top: ${props => props.theme.px('space.11')};
`

const Page = ({ data }) => {
  const postNode = {
    title: `Polstern - ${config.siteTitle}`,
  }

  const blockIntro = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `ueber-uns`,
    headline: `Über uns`,
    copytext: `<p>Die Raumausstattung Ziegler befindet sich zum einen im Herzen der Stadt Ludwigsburg, in der tollen Eberhardstraße. Und des Weiteren ist unser Stammhaus in Gundelsheim am Neckar.</p> 
<p>Unsere Leidenschaft für die Raumausstattung und der Handel mit Farben und Lacken sind seit Generationen fester Bestandteil unserer Familie.</p>
<p>Seit mehr als 100 Jahren ist die Firma Ziegler in Gundelsheim für ihre Kunden und Freunde anzutreffen und mittlerweile in den Händen der 4. Generation angekommen.</p>
<p>Auch der Standort Ludwigsburg blickt auf eine langen Raumausstatter-Tradition zurück. Da die Firma in Ludwigsburg, ehemals Raumausstattung Feuerstein gegründet vor über 60 Jahren, seit 2005 von Herrn Bernhard Ziegler weitergeführt wird.</p>
`,
  }

  const blockSuchenDich = {
    variant: VARIANT_TEXT_INTENDED,
    lead: `Jetzt suchen wir Dich zur Verstärkung unseres Teams in Ludwigsburg oder Gundelsheim!`,
  }

  const blockRaumausstatter = {
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    id: `raumausstatter-in`,
    headline: `Raumausstatter/-in`,
    copytext: `<p>Du hast die Möglichkeit in allen Bereichen der Raumausstattung tätig zu sein und hast somit ein sehr abwechslungsreiches Arbeitsfeld. Wir arbeiten hauptsächlich bei Privatkunden und führen Aufträge auch für öffentliche Auftraggeber aus, wo wir z.B. im Schloss Ludwigsburg tätig sind.</p>
<p>Bring gemeinsam mit uns die die Faszination der Raumausstattung nach Ludwigsburg oder nach Gundelsheim.</p>
<p>Wir freuen uns auf deine Bewerbungsunterlagen, die du idealerweise per E-Mail an die untenstehende Adresse sendest.</p>
<p>Wir laden Dich dann gerne zu einem Bewerbungsgespräch ein!</p>`,
  }

  const blockContact = {
    leading: 10,
    id: `ansprechpartner`,
    variant: VARIANT_TEXT_INTENDED,
    headline: `Ansprechpartner`,
    copytext: `<p>Für erste Informationen oder Fragen steht dir Bernhard Ziegler sehr gerne zur Verfügung.</p>
<p>Telefon: 07141 923779 oder 06269 45095</p>
<p>Bewerbungen bitte per E-Mail an: <a href="mailto:info@raumausstattung-ziegler.de?subject=Bewerbung%20als%20Raumausstatter%2F-in"><b>info@raumausstattung-ziegler.de</b></a></p>`,
  }

  const jumpNavItems = [
    {
      label: blockIntro.headline,
      href: `#${blockIntro.id}`,
      active: true,
    },
    {
      label: blockRaumausstatter.headline,
      href: `#${blockRaumausstatter.id}`,
    },
    {
      label: blockContact.headline,
      href: `#${blockContact.id}`,
    },
  ]

  return (
    <Layout>
      <Helmet>
        <title>{`Stellenangebote - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="stellenangebote" customTitle />

      <Main>
        <JumpNavContainer>
          <StyledJumpNav items={jumpNavItems} />
        </JumpNavContainer>
        <MainContent>
          <Article as="header" {...blockIntro}></Article>
          <Article as="div" {...blockSuchenDich}></Article>
          <Article as="div" {...blockRaumausstatter}></Article>
          <Article as="div" {...blockContact}></Article>
        </MainContent>
      </Main>
    </Layout>
  )
}

export default Page
