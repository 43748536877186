import React from 'react'
import styled from 'styled-components'
import { throttle } from 'lodash'
import { cleanHTML } from '../utils'

const Nav = styled.nav`
  font-size: var(--font-size-text-body);
  line-height: var(--line-height-text-body);
`

const NavList = styled.ul`
  position: relative;
`

const NavListItem = styled.li`
  position: relative;

  :first-of-type {
    margin-bottom: ${props => props.theme.px('space.2')};
  }
`

const StyledLink = styled.a`
  position: relative;
  display: block;
  padding: ${props => props.theme.px('space.0')} ${props => props.theme.px('space.4')} ${props => props.theme.px('space.0')} 0;
  font-weight: ${props => props.active ? '600' : 'normal'};
  text-align: right;
  color: ${props => props.active ? '#000' : '#505050'};
  transition: color 0.15s ease-out;

  &:hover {
    color: #000;
  }

  &::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 0;
    display: block;
    width: ${props => props.active ? props.theme.px('space.2') : '0'};
    height: 2px;
    background-color: #abc8b1;
    transition: width 0.3s ease-in-out;
  }
`

class JumpNav extends React.Component {
  constructor(props) {
    super(props)

    this.update = throttle(this.update.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScrollHandler = evt => {
      this.update()
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHandler)
  }

  update() {
    const { items } = this.props
    const fromTop = window.scrollY + 9 * 8;
    items.forEach((link, index) => {
      const section = document.querySelector(link.href);
      if (section) {
        link.active =
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        
          if (index === 0 && fromTop < section.offsetTop) {
            link.active = true
          }
      }
    })
    this.setState({ items });
  }

  render () {
    const { items } = this.props
    const hasItems = items && items.length > 0

    return (
      <Nav {...this.props}>
        <NavList>
          {hasItems && items.map((item, index) => {
            return <NavListItem key={index}>
                <StyledLink
                  href={item.href}
                  active={item.active}>
                  <span dangerouslySetInnerHTML={{
                    __html: cleanHTML(item.label),
                  }}/>
                </StyledLink>
              </NavListItem>
          })}
        </NavList>
      </Nav>
    )
  }
}

JumpNav.defaultProps = {
}

export default JumpNav
